import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import ProjectEu from "./ProjectEu"

const getProjekt = graphql`
  query {
    allContentfulProjektEu(sort: { fields: createdAt, order: DESC }) {
      edges {
        node {
          desc {
            json
          }
          projectFile {
            file {
              url
            }
          }
          id
        }
      }
    }
    allContentfulLaser {
      edges {
        node {
          large: laserImages {
            title
            fixed(width: 1200, quality: 65) {
              ...GatsbyContentfulFixed
              src
              width
              height
            }
          }
          thumbs: laserImages {
            title
            fluid(maxWidth: 500, quality: 65) {
              ...GatsbyContentfulFluid
              src
            }
          }
        }
      }
    }
  }
`

const ProjektEU = () => {
  const query = useStaticQuery(getProjekt)
  const data = query.allContentfulProjektEu.edges
  const images = query.allContentfulLaser.edges

  return (
    <section className="page-section">
      <div className="container">
        {data.map(project => (
          <ProjectEu
            key={project.node.id}
            data={project.node}
            images={images}
          />
        ))}
      </div>
    </section>
  )
}

export default ProjektEU
