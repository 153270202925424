import React from "react"
import Layout from "../components/layout/Layout"
import SecondaryHeader from "../components/sections/SecondaryHeader"
import SEO from "../components/SEO"
import seotext from "../constants/seotext"
import ProjectsEU from "../components/projects EU/ProjectsEu"
import Cta from "../components/Cta"
import { graphql, useStaticQuery } from "gatsby"

export default ({ pageContext }) => {

  const image = useStaticQuery(graphql`
    query {
      laser: contentfulAsset(title: { eq: "CANR8396" }) {
        fluid(maxWidth: 1600, quality: 50) {
          ...GatsbyContentfulFluid
        }
      }
    }
  `)

  return (
    <Layout>
      <SEO title="Projekty EU" description={seotext.laser} />
      <SecondaryHeader
        pageContext={pageContext}
        disable={["/projekteu"]}
        heading="Projekty"
        bold="EU"
        image={image.laser.fluid}
      />
      <ProjectsEU />
      <Cta small />
    </Layout>
  )
}
