import React from "react"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import Gallery from "../gallery/Gallery"
import styles from "./ProjectEu.module.scss"
import { BLOCKS } from "@contentful/rich-text-types"
import PropTypes from 'prop-types';
import Fade from "react-reveal/Fade"

const ProjectEu = ({ data, images }) => {
  const options = {
    renderNode: {
      [BLOCKS.HEADING_4]: (node, children) => <h3>{children}</h3>,
      [BLOCKS.PARAGRAPH]: (node, children) => (
        <Fade bottom duration={700}>
          <p className="text">{children}</p>
        </Fade>
      ),
    },
  }

  return (
    <>
      <Fade bottom duration={700}>
        <h4 style={{ marginBottom: "2rem" }}>
          Pořízení technologie laser pálící stroj
        </h4>
      </Fade>
      <div>{documentToReactComponents(data.desc.json, options)}</div>
      <Fade bottom duration={700}>
        <a className={styles.link} href={data.projectFile.file.url}>
          Soubor projektu zde
        </a>
      </Fade>
      <Gallery
        large={images[0].node.large}
        thumb={images[0].node.thumbs}
        isLaser
      />
    </>
  )
}

export default ProjectEu

ProjectEu.propTypes = {
  data: PropTypes.object,
  images: PropTypes.array
}
